import styled from '@emotion/styled'

const H1 = styled.h1`
  font-family: FSAlbert;
  font-weight: 800;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 27px;
`

const H2 = styled.h2`
  font-family: FSAlbert;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
`

export { H1, H2 }
