import React from 'react'
import styled from '@emotion/styled'
import logo from '../images/logo.png'

const Wrapper = styled.div`
  background-color: #0a6fb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  z-index: 999;
  width: 130px;
  height: 100px;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  img {
    width: 96px;
    display: block;
  }

  @media screen and (max-width: 1120px) {
    left: 40px;
  }

  @media screen and (max-width: 1024px) {
    width: 78px;
    height: 65px;
    img {
      width: 54px;
    }
  }

  @media screen and (max-width: 768px) {
    left: 16px;
    width: 65px;
    height: 54px;
    img {
      width: 45px;
    }
  }
`

const HeaderLogo = () => (
  <Wrapper>
    <img src={logo} alt="Q8 Easy" />
  </Wrapper>
)

export { HeaderLogo }
