import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { calculateDiscount } from './DiscountDisplay'
import styled from '@emotion/styled'
import { Paragraph } from './components/Paragraph'
import { H1 } from './components/Title'
import promoStar from './images/promo-blue.png'
import { Animated } from 'react-animated-css'
import patch from './images/v4/patch.png'

const Wrapper = styled.div`
  height: auto;
  max-height: ${({ show }) => (show ? '1000px' : 0)};
  background-color: #c30f1d;
  transition: max-height 0.2s ease;
`

const Content = styled.div`
  max-width: 760px;
  margin: 0 auto;
  padding: 45px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    width: 600px;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 30px 20px 40px;
  }
`

const Promo = styled.figure`
  height: 276px;
  width: 276px;
  flex: 0 1 276px;
  background-image: url(${promoStar});
  background-size: cover;
  display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  font-family: FSAlbert;
  font-weight: 800;
  color: #d33331;
  padding: 0;
  margin: 0;
  font-size: 70px;
  position: relative;

  span {
    font-size: 34px;
    position: relative;
    top: 10px;
    left: -6px;
  }

  .patch {
    position: absolute;
    bottom: 50px;
    right: -25%;
    width: 40%;
  }

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    width: 206px;
    height: 206px;
    flex: 0 1 206px;
    font-size: 50px;

    span {
      font-size: 28px;
      top: 6px;
    }

    .patch {
      position: absolute;
      bottom: 0px;
      right: -20px;
      width: 40%;
    }
  }

  @media screen and (max-width: 768px) {
    display: ${({ mobile }) => (!mobile ? 'none' : 'flex')};
    margin: 30px auto;
    width: 200px;
    height: 200px;
    font-size: 54px;
    span {
      font-size: 24px;
      top: 7px;
    }

    .patch {
      position: absolute;
      bottom: 10px;
      right: -30px;
      width: 40%;
    }
  }
`

const TextContent = styled.div`
  flex: 1;
  max-width: 370px;

  h1 {
    margin-bottom: 25px;
    font-size: 25px;

    @media screen and (max-width: 480px) {
      font-size: 24px;
      text-align: center;
    }
  }

  p {
    font-size: 20px;
    line-height: 26px;
    padding-right: 10px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`

const DiscountDialog = ({ values, show, t }) => {
  const [showPromo, setShowPromo] = useState(false)

  useEffect(() => {
    if (show) {
      setShowPromo(true)
    }
  }, [show])

  return (
    <Wrapper show={show} className={`discount-dialog`}>
      <Content>
        <TextContent>
          <H1>{t('dialog_title')}</H1>
          <Animated animationIn="tada" show={showPromo}>
            <Promo mobile>
              <span>&euro;</span>
              {(
                values.liter * values.discount -
                calculateDiscount(values.liter, values.official)
              ).toFixed(2)}
              <img src={patch} className="patch patch--visibility" alt="" />
            </Promo>
          </Animated>
          <Paragraph>{t('dialog_text')}</Paragraph>
          <br />
          <Paragraph>{t('dialog_text_2')}</Paragraph>
        </TextContent>
        <Animated animationIn="tada" show={showPromo}>
          <Promo>
            <span>&euro;</span>
            {(
              values.liter * values.official -
              calculateDiscount(values.liter, values.official)
            ).toFixed(2)}
            <img src={patch} className="patch patch--visibility" alt="" />
          </Promo>
        </Animated>
      </Content>
    </Wrapper>
  )
}

export default translate()(DiscountDialog)
