import React, { Component } from 'react'
import { sortBy, compose, toLower, prop } from 'ramda'
import { Helmet } from 'react-helmet'
import { translate } from 'react-i18next'
import numberIsNan from 'number-is-nan'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'

// import i18n from './i18n'
// import logoActie from './images/logo-nl-3okt.png'
// import logoActieFr from './images/logo-fr-3okt.png'
// import logoPijl from './images/pijlke.svg'

const sortByTitle = sortBy(
  compose(
    toLower,
    prop('label'),
  ),
)

const mapStationsToSelectValues = station => ({
  label: station.title.rendered,
  value: station.id,
})

const mapLitersToSelectValues = t => amount => ({
  label: `${amount} ${t('liters')}`,
  value: amount,
})

const Wrapper = styled.section`
  width: 470px;
  padding-right: 30px;

  @media screen and (max-width: 1024px) {
    width: 600px;
    margin: 0 auto 40px;
    padding-right: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`

const Submit = styled.button`
  max-width: 345px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    max-width: none;
  }
`

class Configurator extends Component {
  state = {
    selectedStation: null,
    selectedFuelType: null,
    selectedLiterAmount: null,
    didSubmit: false,
  }

  fuelTypes = [
    {
      label: this.props.t('diesel'),
      value: 'diesel',
    },
    {
      label: this.props.t('euro'),
      value: 'euro',
    },
    {
      label: this.props.t('super'),
      value: 'super',
    },
  ]

  literAmounts = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]

  updateLiters = selectedLiterAmount => {
    this.setState({
      selectedLiterAmount,
    })
  }

  updateFuelType = v => {
    this.setState({
      selectedFuelType: this.fuelTypes.find(f => f.value === v),
    })
  }

  updateStation = selectedStation => {
    this.setState({
      selectedStation,
    })
  }

  onSubmit = () => {
    this.setState({ didSubmit: true, animate: true })
    setTimeout(
      () =>
        this.setState({
          animate: false,
        }),
      3001,
    )
    this.props.onSubmitClick(this.state)
  }

  render() {
    const { t } = this.props

    return (
      <Wrapper className="Configurator">
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>

        <div className="configurator__inputs">
          <div>
            <Select
              id="station-select"
              clearable
              searchable
              className="input__text"
              placeholder={t('q8-easy-station')}
              noResultsText={t('stations-loading')}
              value={this.state.selectedStation}
              onChange={this.updateStation}
              options={sortByTitle(
                this.props.stations.map(mapStationsToSelectValues),
              )}
            />
            <div className="fuel-buttons">
              {this.fuelTypes.map(fuel => (
                <button
                  key={fuel.value}
                  className={`button button--fuel ${
                    this.state.selectedFuelType &&
                    this.state.selectedFuelType.value === fuel.value
                      ? 'selected'
                      : ''
                  }`}
                  value={fuel.value}
                  onClick={() => this.updateFuelType(fuel.value)}>
                  {this.state.selectedFuelType &&
                    this.state.selectedFuelType.value === fuel.value && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                  <span>{fuel.label}</span>
                </button>
              ))}
            </div>
            <Select
              id="liter-amount"
              searchable={false}
              placeholder={t('liter-amount')}
              className="input__text liters-input"
              value={this.state.selectedLiterAmount}
              onChange={this.updateLiters}
              options={this.literAmounts.map(mapLitersToSelectValues(t))}
            />

            <Submit
              type="submit"
              disabled={
                !this.state.selectedStation ||
                !this.state.selectedFuelType ||
                !this.state.selectedLiterAmount ||
                (this.state.selectedLiterAmount &&
                  this.state.selectedLiterAmount.value &&
                  numberIsNan(
                    parseInt(this.state.selectedLiterAmount.value, 10),
                  ))
              }
              className="button button--configurator"
              onClick={this.onSubmit}>
              {this.state.didSubmit ? t('recalculate') : t('calculate')}
            </Submit>
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default translate()(Configurator)
