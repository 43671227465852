import React, { useState, useEffect } from 'react'
import i18n from '../i18n'
import logoActie from '../images/v4/logo.png'
import logoActieFr from '../images/v4/logo-fr.png'
import logoPijl from '../images/v3/logo-pijl.png'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 18px;
  @media screen and (max-width: 480px) {
    padding-top: 90px;
  }
`

const LogoContainer = styled.figure`
  position: relative;
`

const Logo = styled.img`
  width: 270px;
  display: block;

  @media screen and (max-width: 768px) {
    width: 230px;
    max-width: 100%;
    min-width: 230px;
  }
`

const LogoArrow = styled.img`
  width: 27px;
  height: 27px;
  position: absolute;
  top: 11px;
  right: 24px;

  @media screen and (max-width: 768px) {
    width: 23px;
    height: 23px;
    top: 9px;
    right: 20.5px;
  }
`

const AppLogo = ({ animate }) => {
  const [fullA, setFullA] = useState(false)

  useEffect(() => {
    if (animate) {
      return setFullA(true)
    }
    return setFullA(false)
  }, [animate])
  return (
    <Wrapper>
      <LogoContainer>
        <Logo
          src={i18n.language === 'nl' ? logoActie : logoActieFr}
          alt=""
          width="270"
        />
        <LogoArrow
          className={`animate configurator__logo-pijl ${fullA &&
            'animate--full'}`}
          src={logoPijl}
          width="27"
          alt=""
        />
      </LogoContainer>
    </Wrapper>
  )
}

export { AppLogo }
