import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { I18nextProvider } from 'react-i18next'
import { fetchStations } from './api'
import i18n from './i18n'
import $ from 'jquery'
import Configurator from './Configurator'
import DiscountDisplay from './DiscountDisplay'
import DiscountDialog from './DiscountDialog'
import { HeaderLogo } from './components/HeaderLogo'
import { Container } from './components/Container'
import { AppLogo } from './components/AppLogo'
import { H1 } from './components/Title'
import { TextAlign } from './components/TextAlign'
import { Paragraph } from './components/Paragraph'
import { Smiles, SmilesPortal } from './components/Smiles'

import 'animate.css/animate.min.css'
import 'react-select/dist/react-select.css'
import './odometer.css'
import './App.css'

const Header = styled.header`
  margin-bottom: 55px;

  @media screen and (max-width: 480px) {
    margin-bottom: 30px;
  }
`

const HeaderText = styled(Paragraph)`
  max-width: 46%;
  margin: 12px auto 0;

  @media screen and (max-width: 768px) {
    max-width: 75%;
  }

  @media screen and (max-width: 480px) {
    max-width: 90%;
  }
`

const InputSection = styled.section`
  background-color: #d33331;
  border-radius: 15px 15px 0 0;
  padding: 40px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 480px) {
    padding: 30px 20px;
  }
`

class App extends Component {
  state = {
    showPromo: false,
    didSubmit: false,
    stations: [],
    values: {
      liter: null,
      official: null,
      discount: null,
    },
  }

  componentDidMount() {
    if (window.location.href.includes('mercrediaprixreduit')) {
      i18n.changeLanguage('fr')
    } else {
      i18n.changeLanguage('nl')
    }
    this.fetchStations()
  }

  fetchStations = async () => {
    const data = await fetchStations()
    this.setState({ stations: data })
  }

  submitConfigurator = values => {
    const station = this.state.stations.filter(
      station => station.id === values.selectedStation.value,
    )[0]

    this.setState({ didSubmit: true })

    setTimeout(() => {
      this.setState({
        values: {
          liter: parseFloat(values.selectedLiterAmount.value),
          official: parseFloat(
            station.acf[`${values.selectedFuelType.value}_official`].replace(
              ',',
              '.',
            ),
          ),
          discount: parseFloat(
            station.acf[`${values.selectedFuelType.value}_discount`].replace(
              ',',
              '.',
            ),
          ),
        },
        showPromo: false,
      })

      $([document.documentElement, document.body]).animate(
        {
          scrollTop:
            $('.DiscountDisplay').offset().top -
            ($(window).width() < 1024 ? 0 : 80),
        },
        800,
      )

      setTimeout(() => {
        this.setState({
          showPromo: true,
          didSubmit: false,
        })
      }, 4500)
    }, 100)
  }

  hidePromo = () => {
    this.setState({ showPromo: false, didSubmit: false })
  }

  render() {
    return (
      <>
        <Global
          styles={css`
            body {
              background-color: #94c0e8;
            }
          `}
        />
        <I18nextProvider i18n={i18n}>
          <Container>
            <Header>
              <HeaderLogo />
              <AppLogo animate={this.state.didSubmit} />
              <TextAlign
                align="center"
                style={{
                  display: 'block',
                  marginTop: 63,
                }}>
                <H1>{i18n.t('configurator_title')}</H1>
                <HeaderText>{i18n.t('configurator_text')}</HeaderText>
              </TextAlign>
            </Header>

            <InputSection>
              <Configurator
                stations={this.state.stations}
                onSubmitClick={this.submitConfigurator}
              />
              <DiscountDisplay
                values={this.state.values}
                show={this.state.showPromo}
              />
            </InputSection>

            {this.state.showPromo && (
              <DiscountDialog
                values={this.state.values}
                show={this.state.showPromo}
                hidePromo={this.hidePromo}
              />
            )}

            <SmilesPortal>
              <Container>
                <Smiles />
              </Container>
            </SmilesPortal>
          </Container>
        </I18nextProvider>
      </>
    )
  }
}

export default App
