import i18n from 'i18next'

import nl from './locales/nl/translations.json'
import fr from './locales/fr/translations.json'

i18n.init({
  lng: 'nl',
  fallbackLng: 'nl',
  defaultNS: 'q8',
  whitelist: ['fr', 'nl'],
  lowerCaseLng: true,
  resources: {
    nl,
    fr,
  },
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

export default i18n
