import React from 'react'
import ReactDOM from 'react-dom'
import 'reset-css'
import $ from 'jquery'
import './index.css'
import App from './App'
require('string.prototype.includes')

const $html = $('html')

if (window.navigator.userAgent.indexOf('Edge') > -1) {
  $html.addClass('edge')
}

if (/Trident.*rv[ :]*11\./.test(navigator.userAgent)) {
  $html.addClass('ie11')
}

if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
  $html.addClass('device-ios')
}
if (navigator.userAgent.match(/android/i)) {
  $html.addClass('device-android')
}

if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
  $html.addClass('firefox')
}

ReactDOM.render(<App />, document.getElementById('root'))
