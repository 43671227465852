import styled from '@emotion/styled'

const Paragraph = styled.p`
  font-family: FSAlbert;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 24px;

  strong {
    font-family: FSAlbert;
    font-weight: 600;
  }
`

export { Paragraph }
