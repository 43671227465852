import axios from 'axios'
import { compose, flatten, map, prop, path } from 'ramda'

const flatMap = compose(flatten, map)

export const fetchStations = async () => {
  const query = await axios.get('/wp-json/wp/v2/station?per_page=100')
  if (path(['headers', 'x-wp-totalpages'], query)) {
    const totalPages = parseInt(query.headers['x-wp-totalpages'], 10)
    const promises = [...Array(totalPages)].map((_, index) =>
      axios.get(`/wp-json/wp/v2/station?page=${index + 1}&per_page=100`),
    )

    const responses = await Promise.all(promises)
    return flatMap(prop('data'), responses)
  }
}
