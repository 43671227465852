import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { H1, H2 } from './Title'
import { TextAlign } from './TextAlign'
import { Paragraph } from './Paragraph'
import iciParis from '../images/v3/ici-paris.png'
import krefel from '../images/v4/krefel.png'
import q8 from '../images/v3/q8.png'
import { translate } from 'react-i18next'

const Wrapper = styled.section`
  background-color: #0a6fb8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 40px 160px 90px;
  margin-bottom: 110px;
  display: flex;

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    padding: 40px 40px 90px;
    margin: 0 auto 110px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
    margin-bottom: 55px;
    flex: 1;
  }
`

const Columns = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Column = styled.div`
  flex: 1 1 0;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`

const Logo = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    width: 65%;
    margin: 0 auto 10px;
    + p {
      margin-bottom: 32px;
    }
  }
`

const CtaLink = styled.a`
  background: #ffffff;
  border-radius: 24px;
  font-family: Helvetica;
  font-size: 25px;
  color: #000000;
  letter-spacing: 0;
  font-family: FSAlbert;
  font-weight: 600;
  font-size: 18px;
  color: #0a6fb8;
  letter-spacing: 0;
  text-align: center;
  padding: 16px 45px;
  text-decoration: none;
  display: inline-block;
`

const Smiles = translate()(({ t }) => (
  <Wrapper>
    <TextAlign align="center">
      <H1 style={{ marginBottom: 15 }}>
        {t('smiles.nog_meer_besparen')}
        <br />
        {t('smiles.registreer_voor_smiles')}
      </H1>
      <Paragraph style={{ marginBottom: 56 }}>
        <span dangerouslySetInnerHTML={{ __html: t('smiles.besparen_text') }} />
        <br />
        <br />
        <span
          dangerouslySetInnerHTML={{ __html: t('smiles.besparen_text_2') }}
        />
      </Paragraph>

      <Columns>
        <Column>
          <Logo src={iciParis} alt="" />
          <Paragraph>
            <strong
              dangerouslySetInnerHTML={{ __html: t('smiles.cadeaubon') }}
            />
          </Paragraph>
        </Column>
        <Column>
          <Logo src={krefel} alt="" />
          <Paragraph>
            <strong
              dangerouslySetInnerHTML={{ __html: t('smiles.cadeaubon') }}
            />
          </Paragraph>
        </Column>
        <Column>
          <Logo
            src={q8}
            alt=""
            style={{ width: 101, marginLeft: 'auto', marginRight: 'auto' }}
          />
          <Paragraph>
            <strong
              dangerouslySetInnerHTML={{ __html: t('smiles.kortingsbon') }}
            />
          </Paragraph>
        </Column>
      </Columns>

      <H2 style={{ marginBottom: 11 }}>
        <span dangerouslySetInnerHTML={{ __html: t('smiles.easy_betalen') }} />
      </H2>
      <div>
        <Paragraph style={{ marginBottom: 78 }}>
          <span
            dangerouslySetInnerHTML={{
              __html: t('smiles.easy_betalen_text'),
            }}
          />
        </Paragraph>
      </div>

      <CtaLink href="/">{t('smiles.register')}</CtaLink>
    </TextAlign>
  </Wrapper>
))

const SmilesPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('smiles'))

export { Smiles, SmilesPortal }
