import React from 'react'
import Odometer from 'react-odometerjs'
import logoActie from './images/v3/logo-actie-wit.png'
import logoActieFr from './images/v3/logo-actie-wit-fr.png'
import { translate } from 'react-i18next'
import i18n from './i18n'
import styled from '@emotion/styled'

import { cx, css } from 'emotion'

export const calculateDiscount = (liter, official) =>
  liter * official - liter * 0.2

const formatValueForOdometer = value => {
  if (value) {
    if (value >= 10) return parseFloat(`1${value.toFixed(2)}1`)
    if (value < 10) return parseFloat(`10${value.toFixed(2)}1`)
  } else return parseFloat(`${100.001}`)
}

const Wrapper = styled.section`
  width: 500px;

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    margin: 0 auto;
    width: 600px;
    position: relative;
    border-top: 1px solid #c30f1d;
    padding-top: 40px;
    > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border-top: 1px solid #c30f1d;
    padding-top: 25px;
    margin-top: 40px;
  }
`

const counterLabelImage = css`
  width: 134px;

  @media screen and (max-width: 768px) {
    width: 160px;
  }

  @media screen and (max-width: 400px) {
    width: 120px;
  }
`
const DiscountDisplay = ({ t, show, values }) => {
  return (
    <Wrapper
      className={`DiscountDisplay ${
        show ? 'DiscountDisplay--add-height' : ''
      }`}>
      <div>
        <div className="counter counter-odo counter-odo-1">
          <p className="counter-label">{t('liter')}</p>
          <Odometer
            value={formatValueForOdometer(values.liter)}
            format="(ddd),ddd"
            duration={3000}
          />
        </div>
        <div className="counter counter-odo counter-odo-2">
          <p className="counter-label">{t('official-price')}</p>
          <Odometer
            value={formatValueForOdometer(values.liter * values.official)}
            format="(ddd),ddd"
            duration={5000}
          />
        </div>
        <div className="counter counter-odo counter-odo-3">
          <p className={cx('counter-label', 'counter-label-smaller')}>
            {t('q8-discount-price')}
          </p>
          <Odometer
            value={formatValueForOdometer(values.liter * values.discount)}
            format="(ddd),ddd"
            duration={7000}
          />
        </div>
        <div className="counter counter-odo-4">
          <p className="counter-label">
            <img
              src={i18n.language === 'nl' ? logoActie : logoActieFr}
              className={cx('counter-label-image', counterLabelImage)}
              alt=""
              width="134"
            />
          </p>
          <Odometer
            value={formatValueForOdometer(
              calculateDiscount(values.liter, values.official),
            )}
            duration={9000}
            format="(ddd),ddd"
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default translate()(DiscountDisplay)
