import styled from '@emotion/styled'

const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 1120px) {
    width: calc(100% - 80px);
    padding: 0 40px;
  }

  @media screen and (max-width: 1024px) {
    width: 680px;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

export { Container }
